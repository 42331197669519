import { Injectable, EventEmitter } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';
import { FLOCK_NUMBER, FLOCK_REQUEST_KEY } from 'src/app/interceptors/my-flock.interceptor';
import { HttpContext, HttpParams } from '@angular/common/http';
import { KEY_VKI_REQUEST } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class VKIRequestService {
  selectedCurrentCompany: any;

  constructor(private http: Https) {}

  public getVKIRequestData(environmentId, flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_VKI_REQUEST);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/vki/${environmentId}/${flockNumber}`, undefined, undefined, context);
  }

  public saveVKIRequestSubscription(payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_VKI_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/vki`, payload, undefined, context);
  }

  public getSlaughterLocationList() {
    return this.http.get(`${environment.baseUrlV2}/plukonKras/slaughters`, '');
  }

  public getSlaughterLocationInEnvironment(environmentId, slaughterLocationId) {
    return this.http.get(`${environment.baseUrl}/environments/preferSlaughterLocation/${environmentId}/${slaughterLocationId}`, '');
  }

  public getCatchcrew(plukonId) {
    const params = new HttpParams().set('companyPlukonId', plukonId);
    return this.http.get(`${environment.baseUrl}/plukonKras/catchcrew`, params);
  }

  set vkiRequestData(value: any) {
    this.selectedCurrentCompany = value;
  }

  get vkiRequestData(): any {
    return this.selectedCurrentCompany;
  }
}
