import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  currentCompanyData: any;
  currentKrasData: any;
  currentStableData: any;

  constructor(private http: Https) {}

  public getCompanyList(isMainCompany, mainEnvironment) {
    let params;
    if (isMainCompany) {
      params = new HttpParams().set('sortBy', 'name').set('orders', 'ASC').set('isMainCompany', isMainCompany);
    } else {
      params = new HttpParams().set('sortBy', 'name').set('orders', 'ASC');
    }
    if (mainEnvironment) {
      return this.http.get(`${environment.baseUrl}/companies/environment/${mainEnvironment}`, params);
    } else {
      return this.http.get(`${environment.baseUrl}/companies`, params);
    }
  }

  public getCompanyListById(payload) {
    return this.http.get(`${environment.baseUrl}/companies/${payload}`, '');
  }


  public getNewPlukonCompanyList(shortName, companyType) {
    const params = {
      envShortName: shortName,
      companyType: companyType
    };
    return this.http.get(`${environment.baseUrlV2}/plukonKras/companies`, params);
  }

  public getPlukonCompanyDetailV2(payload) {
    return this.http.get(`${environment.baseUrlV2}/plukonKras/companies/${payload}`, '');
  }

  public getPlukonStableDetail(payload) {
    return this.http.get(`${environment.baseUrlV2}/plukonKras/stables/${payload}`, '');
  }

  public getMainCompany() {
    return this.http.get(`${environment.baseUrl}/companies/mainCompanies`, null);
  }

  public getUserPortalCompanyList(mainEnvironment) {
    if (mainEnvironment) {
      return this.http.get(`${environment.baseUrl}/companies/userportal/${mainEnvironment}`, null);
    } else {
      return this.http.get(`${environment.baseUrl}/companies/userportal`, null);
    }
  }

  public getSubCompanyByCompany(payload) {
    return this.http.get(`${environment.baseUrl}/companies/subCompanies/${payload}`, null);
  }

  public getMainCompanyByEnvironment(payload) {
    return this.http.get(`${environment.baseUrl}/companies/environment/${payload}/mainCompanies`, null);
  }

  public checkIBAN(payload) {
    return this.http.get(`${environment.baseUrl}/ibanrechner/${payload}`, null);
  }

  public createOrUpdateCompany(payload, saveMode) {
    //saveMode true = save, false = update
    if (saveMode) {
      return this.http.post(`${environment.baseUrl}/companies`, payload);
    } else {
      return this.http.post(`${environment.baseUrl}/companies/update`, payload);
    }
  }

  public approvePlukonCompanyDetail(plukonId) {
    return this.http.post(`${environment.baseUrlV2}/plukonKras/companies/approve/${plukonId}`, {});
  }

  public savePlukonCompanyDetailV2(payload) {
    return this.http.post(`${environment.baseUrlV2}/plukonKras/companies`, payload);
  }

  public deleteCompany(payload) {
    return this.http.post(`${environment.baseUrl}/companies/delete/${payload}`, '');
  }

  set companyData(value: any) {
    this.currentCompanyData = value;
  }

  get companyData(): any {
    return this.currentCompanyData;
  }

  set krasData(value: any) {
    this.currentKrasData = value;
  }

  get krasData(): any {
    return this.currentKrasData;
  }

  set stableData(value: any) {
    this.currentStableData = value;
  }

  get stableData(): any {
    return this.currentStableData;
  }
}
