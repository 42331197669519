import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private http: Https) {}

  public getEnvironmentList(defaultEnvironment?) {
    let environmentShortName = defaultEnvironment;
    if (environmentShortName == null) {
      environmentShortName = '';
    }
    const params = new HttpParams().set('sortBy', 'name').set('orders', 'ASC').set('shortName', environmentShortName);
    // return this.http.get(`${environment.baseUrl}/environments`, params);
    return this.http.get(`${environment.baseUrl}/environments`, params, { allowWithoutAuth: true });
  }

  public getEnvironmentListById(payload) {
    const params = new HttpParams().set('sortBy', 'name').set('orders', 'DESC');
    return this.http.get(`${environment.baseUrl}/environments/${payload}`, params);
  }

  public getUserEnvironment(payload) {
    return this.http.get(`${environment.baseUrl}/environments/shortName/${payload}`, '', { skipAuth: true });
  }

  public getSlaughterLocationList() {
    return this.http.get(`${environment.baseUrlV2}/plukonKras/slaughters`, '');
  }

  public createOrUpdateEnvironment(payload, saveMode) {
    //saveMode true = save, false = update
    if (saveMode) {
      return this.http.post(`${environment.baseUrl}/environments`, payload);
    } else {
      return this.http.post(`${environment.baseUrl}/environments/update`, payload);
    }
  }

  public deleteEnvironment(payload) {
    return this.http.post(`${environment.baseUrl}/environments/delete/${payload}`, '');
  }
}
