import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Https } from '../../http.service';
import { FLOCK_NUMBER, FLOCK_REQUEST_EXTRA_KEY, FLOCK_REQUEST_KEY, METADATA } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_CANCEL_REQUEST, KEY_FLOCK_STEP_SALMONELLA, KEY_UPLOAD_TEMP_FILE } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class SalmonellaService {
  currentSalmonellaData: any;
  currentSalmonellaDataOri: any;
  salmonellaStatusListData: any;
  currentUploadDataOri: any;

  constructor(private http: Https) {}

  public getSalmonella(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_SALMONELLA);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/salmonella/${flockNumber}`, undefined, undefined, context);
  }

  public getSalmonellaVKICheck(flockNumber) {
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/salmonella/vkiCheck/${flockNumber}`, '');
  }

  public saveSalmonella(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_SALMONELLA);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/salmonella`, payload, undefined, context);
  }

  public getResultList() {
    return this.http.get(`${environment.baseUrl}/plukonKras/labotorium/salmonella`, '');
  }

  public getLaboratoryList() {
    return this.http.get(`${environment.baseUrlV2}/plukonKras/laboratories`, '');
  }

  public uploadFile(file) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, file.get('flockNumber'));
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_SALMONELLA);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_UPLOAD_TEMP_FILE);
    context.set(METADATA, { uuid: file.get('uuid') });
    return this.http.upload(`${environment.baseUrl}/plukonKras/flock/salmonella/tempfile`, file, undefined, context);
  }

  public cancelUpload(payload) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, payload?.flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_SALMONELLA);
    context.set(FLOCK_REQUEST_EXTRA_KEY, KEY_CANCEL_REQUEST);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/salmonella/tempfile/cancel`, payload, undefined, context);
  }

  set salmonellaData(value: any) {
    this.currentSalmonellaData = value;
  }

  get salmonellaData(): any {
    return this.currentSalmonellaData;
  }

  set salmonellaDataOri(value: any) {
    this.currentSalmonellaDataOri = value;
  }

  get salmonellaDataOri(): any {
    return this.currentSalmonellaDataOri;
  }

  set salmonellaStatusData(value: any) {
    this.salmonellaStatusListData = value;
  }

  get salmonellaStatusData(): any {
    return this.salmonellaStatusListData;
  }

  set uploadDataOri(value: any) {
    this.currentUploadDataOri = value;
  }

  get uploadDataOri(): any {
    return this.currentUploadDataOri;
  }
}
