import { Injectable, EventEmitter } from '@angular/core';
import { HttpContext, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Https } from '../http.service';
import { BehaviorSubject } from 'rxjs';
import { FLOCK_BASICS_REQUEST, FLOCK_NUMBER, FLOCK_REQUEST_KEY } from 'src/app/interceptors/my-flock.interceptor';
import { KEY_FLOCK_STEP_TECHNICAL, KEY_STABLE_LOG_LOSSES, KEY_STABLE_LOG_TECHNICAL } from 'src/lib/my-flock/constants';

@Injectable({
  providedIn: 'root',
})
export class VKIService {
  currentFlockData: any;
  stable: any;
  currentCheckedStatus: any;
  currentFormStatus: any;
  vkiCheck: BehaviorSubject<any>;
  removeVKI = new EventEmitter();
  stepControl: BehaviorSubject<any>;
  removeVKIAction: BehaviorSubject<any>;
  flockBoxInfo: BehaviorSubject<any>;
  flockTechnical: BehaviorSubject<any>;
  flockBoxVisibility: BehaviorSubject<boolean>;
  flockStartDate: BehaviorSubject<any>;
  isFirstStep: BehaviorSubject<boolean>;
  stableList: BehaviorSubject<any>;
  selectedStableId: BehaviorSubject<any>;
  removeButtonTempBlock: BehaviorSubject<any>;

  constructor(private http: Https) {
    this.removeVKIAction = new BehaviorSubject<any>(null);
    this.flockBoxInfo = new BehaviorSubject<any>(null);
    this.flockBoxVisibility = new BehaviorSubject<boolean>(true);
    this.flockStartDate = new BehaviorSubject<any>(null);
    this.vkiCheck = new BehaviorSubject<any>(null);
    this.stepControl = new BehaviorSubject<any>(null);
    this.flockTechnical = new BehaviorSubject<any>(null);
    this.isFirstStep = new BehaviorSubject<any>(true);
    this.stableList = new BehaviorSubject<any>(null);
    this.selectedStableId = new BehaviorSubject<any>(null);
    this.removeButtonTempBlock = new BehaviorSubject<any>(null);
  }

  public getVKIConfig(environmentId, country, slaughterLocation = 'default') {
    return this.http.get(`${environment.baseUrl}/vkiConfig/flock/${environmentId}/${country}/${slaughterLocation}`, '');
  }

  public getStableList(companyId) {
    return this.http.get(`${environment.baseUrl}/stables/${companyId}`, '');
  }

  public getFlockTechnical(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_FLOCK_STEP_TECHNICAL);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/technical/${flockNumber}`, undefined, undefined, context);
  }

  public getFlockBoxInfo(stableId) {
    const context = new HttpContext();
    context.set(FLOCK_BASICS_REQUEST, true);
    return this.http.get(this.getFlockBoxInfoUrl(stableId), undefined, undefined, context);
  }

  getFlockBoxInfoUrl(stableId): any {
    return `${environment.baseUrl}/plukonKras/flock/basics/${stableId}`;
  }

  public getCopyStableList(flockNumber) {
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/copy/${flockNumber}`, '');
  }

  public saveCopyStable(payload) {
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/copy`, payload);
  }

  public getStableLog(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_STABLE_LOG_LOSSES);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/stableLog/mortality/${flockNumber}`, undefined, undefined, context);
  }

  public saveStableLog(payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_STABLE_LOG_LOSSES);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/stableLog/mortality`, payload, undefined, context);
  }

  public createStableLogPdf(flockNumber) {
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/stableLog/mortality/print/${flockNumber}`, '');
  }

  public createTechnicalStableLogPdf(flockNumber) {
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/stableLog/technical/print/${flockNumber}`, '');
  }

  public getLastUpload(stableId, flockNumber) {
    return this.http.get(`${environment.baseUrl}/documents/stableLog/${stableId}/${flockNumber}`, '');
  }

  public getTechnicalLastUpload(stableId, flockNumber) {
    return this.http.get(`${environment.baseUrl}/documents/stableLog/technical/${stableId}/${flockNumber}`, '');
  }

  public getStableLogTechnical(flockNumber) {
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_STABLE_LOG_TECHNICAL);
    return this.http.get(`${environment.baseUrl}/plukonKras/flock/stableLog/technical/${flockNumber}`, undefined, undefined, context);
  }

  public getWeightGainBaseCode() {
    const params = new HttpParams().set('code', 'weightGainBase');
    return this.http.get(`${environment.baseUrl}/codeTables`, params);
  }

  public saveStableLogTechnical(payload) {
    const flockNumber = payload?.flockNumber;
    const context = new HttpContext();
    context.set(FLOCK_NUMBER, flockNumber);
    context.set(FLOCK_REQUEST_KEY, KEY_STABLE_LOG_TECHNICAL);
    return this.http.post(`${environment.baseUrl}/plukonKras/flock/stableLog/technical`, payload, undefined, context);
  }

  set flockData(value: any) {
    this.currentFlockData = value;
  }

  get flockData(): any {
    return this.currentFlockData;
  }

  set currentStable(value: any) {
    this.stable = value;
  }

  get currentStable(): any {
    return this.stable;
  }

  set check(value: any) {
    this.currentCheckedStatus = value;
  }

  get check(): any {
    return this.currentCheckedStatus;
  }

  set formStatus(value: any) {
    this.currentFormStatus = value;
  }

  get formStatus(): any {
    return this.currentFormStatus;
  }
}
